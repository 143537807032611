<template>
  <v-container fill-height>
  <v-row>
    <v-col cols="12"><v-banner v-if="!$store.getters['user/isVerified']" color="info"   dark> 
   Your Account is not verified. Please wait while we verify your account.
   </v-banner></v-col>
     
  </v-row>
    <v-row justify="center" align="center">
      <v-col lg="4">
        <v-card   min-height="350" class="text-center rounded-lg pa-4 glass-card">
          <p class="text-h5 text--white font-weight-bold">Schedule Meetings</p>
        </v-card>
      </v-col>
      <v-col lg="4">
        <v-card
          class="text-center rounded-lg pa-4"
          color="secondary lighten-3"
          min-height="350"
          to="/schedule"
          elevation="5"
        >
          <p style="z-index: -1" class="text-h5 font-weight-bold text--white">
            All in One Online Classroom
          </p>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style>
.glass-card {
  /* From https://css.glass */
  background: rgba(44, 175, 185, 0.185);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.1px);
  -webkit-backdrop-filter: blur(4.1px);
  border: 1px solid rgba(233, 37, 220, 0.23);
}
</style>
